import gql from 'graphql-tag';
import { defineStore } from 'pinia';
import { arrayIncludesAnyElementInOtherArray } from '#root/shared/utils/types/object-utils';
import { ProductStorageClimate } from '#root/shared/types/graphql-types';
import type { Cart } from '#root/shared/types/graphql-types';
import { cartFragment } from '@/graphql/fragments';
import { addDays, compareAsc, formatISO } from 'date-fns';
import { DISCOUNT_TYPES } from '#root/shared/config/discount-config';
import {
  CATEGORY_AND_BRAND_COMBINATION_PURCHASE_LIMIT,
  SKUS_PURCHASE_LIMIT,
} from '@brand/config/cart-config';
import type { NormalizedLoop54Product } from '#root/shared/types/loop54-types';

export const useCartStore = defineStore('cart', () => {
  const subscriptionStore = useSubscriptionStore();
  const enabledFeaturesStore = useEnabledFeaturesStore();

  const cart = ref<Cart | null>(null);
  const cartRecommendations = ref<NormalizedLoop54Product[] | null>(null);
  const promo = ref<Cart['promo'] | null>(null);
  const optimisticItemsCountVariation = ref<number>(0);
  const isUpdatingCart = ref<boolean>(false);
  const shouldSendBrazeAddToCartEventForProductId = ref<number | null>(null);

  const bcItemCount = computed(() => cart.value?.itemCount || 0);
  const cartAmount = computed(
    () =>
      (cart.value?.amount?.value || 0) +
      (enabledFeaturesStore.isFeatureEnabled('subscriptions')
        ? subscriptionStore.subscriptionAmount
        : 0)
  );
  const cartBaseAmount = computed(() => cart.value?.baseAmount?.value || 0);
  const cartCampaignDiscountAmount = computed(
    () => cart.value?.campaignDiscountAmount?.value || 0
  );
  const cartContainsFrozenFood = computed(() =>
    cart.value?.storageClimates?.includes(ProductStorageClimate.Frozen)
  );
  const cartFrozenItemsHandlingFee = computed(() => {
    if (cartContainsFrozenFood.value) {
      return cart.value?.frozenItemsHandlingFee?.value || 0;
    }
    return 0;
  });
  const cartPromo = computed(() => cart.value?.promo || null);
  const cartPromoDiscountAmount = computed(
    () => cart.value?.promoDiscountAmount?.value || 0
  );
  const cartWithSubscriptions = computed(() => {
    if (!useEnabledFeaturesStore().isFeatureEnabled('subscriptions')) {
      return cart.value;
    }
    return {
      ...(cart.value || {}),
      items: [
        ...(cart.value?.items || []),
        ...useSubscriptionStore().subscriptionProducts,
      ],
    };
  });

  const itemCount = computed(() => {
    const subscriptionCount = useSubscriptionStore().subscriptionItemCount || 0;
    return (cart.value?.itemCount || 0) + subscriptionCount;
  });
  const getOptimisticItemCount = computed(() =>
    isUpdatingCart.value
      ? optimisticItemsCountVariation.value + itemCount.value
      : itemCount.value
  );
  const lineItems = computed(() => cart.value?.items || []);
  const cartPromoExcludedBrandNames = computed(() => {
    const promoExcludedBrandIds = cart.value?.promo?.excludedBrands || [];
    if (!promoExcludedBrandIds.length) return [];

    const isPerTotalDiscount =
      cart.value?.promo?.type === DISCOUNT_TYPES.per_total;
    const excludedBrands = cart.value?.items
      ?.filter(
        (item) =>
          item.brand && promoExcludedBrandIds.includes(item.brand.entityId)
      )
      ?.map((item) => item?.brand?.name);
    const allItemsExcluded =
      cart.value?.items?.length === excludedBrands?.length;

    if (excludedBrands?.length && (!isPerTotalDiscount || allItemsExcluded)) {
      return [...new Set(excludedBrands)];
    }
    return [];
  });

  const getItemInCart = (productId: number, variantId: number): any =>
    cart.value?.items?.find(
      (item) => item.productId === productId && item.variantId === variantId
    );

  function getCartItemLimit(
    locale: LocaleTerritory,
    cartItem: Record<string, any>
  ) {
    const { isProd } = useRuntimeConfig().public;
    const env = isProd ? 'prod' : 'dev';

    const categoryAndBrandPurchaseLimit =
      CATEGORY_AND_BRAND_COMBINATION_PURCHASE_LIMIT?.[locale]?.[env]?.find(
        (combo: Record<string, any>) => {
          const hasBrand = combo.brandIds.includes(
            cartItem.meta?.brand?.entityId
          );
          const hasCategory = arrayIncludesAnyElementInOtherArray(
            cartItem.meta.categoryIds,
            combo.categoryIds
          );

          return (
            hasBrand && hasCategory && cartItem.item.quantity > combo.limit
          );
        }
      );

    if (categoryAndBrandPurchaseLimit?.limit) {
      return categoryAndBrandPurchaseLimit.limit;
    }

    const skuPurchaseLimit = SKUS_PURCHASE_LIMIT?.[locale]?.[env]?.find(
      (skuConfig: Record<string, any>) =>
        skuConfig.skus.includes(cartItem.meta.sku) &&
        cartItem.item.quantity > skuConfig.limit
    );

    if (skuPurchaseLimit?.limit) {
      return skuPurchaseLimit.limit;
    }

    return 0;
  }

  // Without enabling the persistent carts in BigCommerce,
  // the carts are reported to last about a week => setting a conservative expiry time
  function isCartExpired(cartModificationDate: Date | null): boolean {
    return cartModificationDate
      ? compareAsc(addDays(cartModificationDate, 5), new Date()) <= 0
      : true;
  }

  function rawItemToCartItemObject(it: Record<string, any>) {
    return {
      item: {
        quantity: it.quantity,
        productId: it.productId,
        variantId: it.variantId,
      },
    };
  }

  async function addToCart({ cartItems }: Record<string, any>) {
    const { $i18n, $notification } = useNuxtApp();

    const cartId = localStorage.getItem('cartId');
    const cartModificationDate = getCartModificationDate();

    if (!cartId || isCartExpired(cartModificationDate)) {
      const oldCartItems = cart.value?.items.map(rawItemToCartItemObject) || [];
      return createCart([...oldCartItems, ...cartItems]);
    }

    let cartItemLimit = 0;

    if (
      cartItems.some((cartItem: Record<string, any>) => {
        const currentQuantity =
          getItemInCart(cartItem.item.productId, cartItem.item.variantId)
            ?.quantity || 0;

        cartItemLimit = getCartItemLimit(
          $i18n.locale.value as LocaleTerritory,
          {
            ...cartItem,
            item: {
              ...cartItem.item,
              quantity: cartItem.item.quantity + currentQuantity,
            },
          }
        );

        return cartItem.meta?.isOnSale && cartItemLimit;
      })
    ) {
      $notification.error(
        $i18n.t('cart.limit_per_customer', { limit: cartItemLimit })
      );
      updateCartModificationDate();
      return getCart();
    }

    try {
      const { $apollo } = useNuxtApp();
      const { data } = await $apollo().mutate({
        mutation: gql`
          mutation AddToCart(
            $id: ID!
            $cartItems: [CartItemInput!]
            $promoCode: String
          ) {
            addCartItems(
              id: $id
              cartItems: $cartItems
              promoCode: $promoCode
            ) {
              ...CartFields
            }
          }
          ${cartFragment}
        `,
        variables: {
          cartItems: cartItems.map((cartItem: { item: any }) => cartItem.item),
          id: cartId,
          promoCode: promo.value?.code,
        },
      });
      setCart(data.addCartItems);
      return data.addCartItems;
    } catch (error) {
      handleCartError(error, cartItems);
      return null;
    }
  }

  async function deleteCartItem(cartItemId: number) {
    const cartId = localStorage.getItem('cartId');
    const cartModificationDate = getCartModificationDate();

    if (isCartExpired(cartModificationDate)) {
      const oldCartItems: any[] =
        cart?.value?.items
          .filter((it) => +it.id !== cartItemId)
          .map(rawItemToCartItemObject) || [];

      if (!oldCartItems?.length) {
        setCart(null);
        return null;
      }

      return createCart(oldCartItems);
    }

    if (cartId) {
      try {
        const { $apollo } = useNuxtApp();

        const { data } = await $apollo().mutate({
          mutation: gql`
            mutation DeleteCartItem(
              $id: ID!
              $cartItemId: ID!
              $promoCode: String
            ) {
              deleteCartItem(
                id: $id
                cartItemId: $cartItemId
                promoCode: $promoCode
              ) {
                ...CartFields
              }
            }
            ${cartFragment}
          `,
          variables: {
            cartItemId,
            id: cartId,
            promoCode: promo?.value?.code,
          },
        });
        const updatedCart = data.deleteCartItem;
        setCart(updatedCart);
        return updatedCart;
      } catch (error) {
        return handleCartError(error, []);
      }
    }
    return null;
  }

  async function createCart(cartItems: any[]): Promise<Cart | null> {
    const { $apollo } = useNuxtApp();

    try {
      const { data } = await $apollo().mutate({
        mutation: gql`
          mutation CreateCart(
            $cartItems: [CartItemInput!]
            $promoCode: String
          ) {
            createCart(cartItems: $cartItems, promoCode: $promoCode) {
              ...CartFields
            }
          }
          ${cartFragment}
        `,
        variables: {
          cartItems: cartItems.map((cartItem: any) => cartItem.item),
          promoCode: promo?.value?.code,
        },
      });

      const newCart = data.createCart;
      updateCartModificationDate();
      setCart(newCart);

      // A new cart is created and it should send the Braze add to cart event
      setShouldSendBrazeAddToCartEventForProductId(
        cartItems[0]?.item?.productId
      );

      return newCart;
    } catch (error) {
      return handleCartError(error, cartItems);
    }
  }

  async function getCart() {
    await useSubscriptionStore().loadSubscriptionProducts();

    let cartId = localStorage.getItem('cartId');

    // Create cart if no cart and subscription products exist
    if (!cartId) {
      const { subscriptionProducts } = useSubscriptionStore();
      if (subscriptionProducts.length > 0) {
        const newCart = await createCart([]);
        cartId = newCart?.id || null;
      }
    }

    const cartModificationDate = getCartModificationDate();

    if (isCartExpired(cartModificationDate)) {
      const oldCartItems =
        cart?.value?.items.map(rawItemToCartItemObject) || [];

      if (!oldCartItems?.length) {
        setCart(null);
        return null;
      }

      return createCart(oldCartItems);
    }

    if (cartId) {
      try {
        const { $apollo } = useNuxtApp();

        const { data } = await $apollo().query({
          fetchPolicy: 'no-cache',
          query: gql`
            query Cart($id: ID!, $promoCode: String) {
              cart(id: $id, promoCode: $promoCode) {
                ...CartFields
              }
            }
            ${cartFragment}
          `,
          variables: {
            id: cartId,
            promoCode: promo?.value?.code,
          },
        });

        const retrievedCart = data.cart;
        setCart(retrievedCart);

        return cart;
      } catch (error) {
        return handleCartError(error, []);
      }
    }
    return null;
  }

  async function getCartRecommendations() {
    const { $apiFetch } = useNuxtApp();

    if (cart) {
      try {
        const recommendations = await $apiFetch(
          '/rest/search/cart-recommendations',
          {
            method: 'POST',
            body: {
              ids: cart.value?.items.map((item) => item.productId),
              take: 3,
            },
            headers: { 'User-Id': useLoop54UserIdCookie().value! },
          }
        );

        setCartRecommendations(recommendations);
        return recommendations;
      } catch (error) {
        console.error(
          'Error fetching cart recommendations',
          error,
          (error as any).response
        );
        setCartRecommendations(null);
      }
    }
    return null;
  }

  function handleCartError(error: any, cartItems: any[]) {
    const is404 =
      error?.graphQLErrors?.[0]?.extensions?.response?.status === 404;

    if (is404) {
      clearCart();

      if (cartItems) {
        return createCart(cartItems);
      }
    }

    return error;
  }

  async function updateCartItem({ itemId, cartItem }: Record<string, any>) {
    const { $i18n, $notification } = useNuxtApp();

    isUpdatingCart.value = true;

    const cartId = localStorage.getItem('cartId');
    const cartModificationDate = getCartModificationDate();

    if (isCartExpired(cartModificationDate)) {
      const currentcartItems = cartItem ? [cartItem] : [];

      const oldCartItems =
        cart?.value?.items
          .filter((it) => it.id !== itemId)
          .map(rawItemToCartItemObject) || [];

      if (!oldCartItems?.length && !currentcartItems?.length) {
        setCart(null);
        return null;
      }

      return createCart([...oldCartItems, ...currentcartItems]);
    }

    if (cartId) {
      const cartItemLimit = getCartItemLimit(
        $i18n.locale.value as LocaleTerritory,
        cartItem
      );

      if (cartItem.meta.isOnSale && cartItemLimit) {
        $notification.error(
          $i18n.t('cart.limit_per_customer', { limit: cartItemLimit })
        );

        return getCart();
      }

      try {
        const { $apollo } = useNuxtApp();

        // Calculate quantity variation and update optimistically
        const quantityDifference =
          cartItem.item.quantity -
          (cart?.value?.items.find((i) => i.id === itemId)?.quantity || 0);
        optimisticItemsCountVariation.value = quantityDifference;

        const { data } = await $apollo().mutate({
          mutation: gql`
            mutation UpdateCartItem(
              $id: ID!
              $itemId: ID!
              $cartItem: CartItemInput!
              $promoCode: String
            ) {
              updateCartItem(
                id: $id
                cartItemId: $itemId
                cartItem: $cartItem
                promoCode: $promoCode
              ) {
                ...CartFields
              }
            }
            ${cartFragment}
          `,
          variables: {
            cartItem: cartItem.item,
            id: cartId,
            itemId,
            promoCode: promo?.value?.code,
          },
        });
        const { updateCartItem: updatedCart } = data;
        setCart(updatedCart);
        isUpdatingCart.value = false;
        return updatedCart;
      } catch (error) {
        isUpdatingCart.value = false;
        handleCartError(error, [cartItem]);
      }
    }
    return null;
  }

  function clearCart() {
    localStorage.removeItem('cartId');
    localStorage.removeItem('cartModificationDate');
    cart.value = null;
    cartRecommendations.value = null;
  }

  function removeCartRecommendation(cartRecommendationId: number) {
    cartRecommendations.value =
      cartRecommendations.value?.filter(
        (cartRecommendation) => +cartRecommendation.id !== cartRecommendationId
      ) || [];
  }

  function getCartModificationDate() {
    const rawValue = localStorage.getItem('cartModificationDate');

    if (!rawValue) return null;

    const parsedDate = new Date(rawValue);

    if (Number.isNaN(parsedDate)) return null;

    return parsedDate;
  }

  function updateCartModificationDate(value = new Date()) {
    localStorage.setItem('cartModificationDate', formatISO(value));
  }

  function setCart(newCart: Cart | null): void {
    const currentCartModificationDate = getCartModificationDate();
    //
    if (newCart && !isCartExpired(currentCartModificationDate)) {
      localStorage.setItem('cartId', newCart.id);
      updateCartModificationDate();
    } else {
      localStorage.removeItem('cartId');
      localStorage.removeItem('cartModificationDate');
    }

    cart.value = newCart;
    promo.value = newCart?.promo || null;
  }

  function setCartRecommendations(newCartRecommendations: any[] | null) {
    cartRecommendations.value = newCartRecommendations;
  }
  function setPromoCode(promoCode: string) {
    promo.value = { code: promoCode };
  }
  function setShouldSendBrazeAddToCartEventForProductId(payload: number) {
    shouldSendBrazeAddToCartEventForProductId.value = payload;
  }

  return {
    cart,
    cartRecommendations,
    promo,
    shouldSendBrazeAddToCartEventForProductId,
    bcItemCount,
    cartAmount,
    cartBaseAmount,
    cartCampaignDiscountAmount,
    cartContainsFrozenFood,
    cartFrozenItemsHandlingFee,
    cartPromo,
    cartPromoDiscountAmount,
    cartWithSubscriptions,
    itemCount,
    getOptimisticItemCount,
    lineItems,
    cartPromoExcludedBrandNames,
    addToCart,
    deleteCartItem,
    createCart,
    getCart,
    getCartRecommendations,
    updateCartItem,
    clearCart,
    removeCartRecommendation,
    setPromoCode,
    setShouldSendBrazeAddToCartEventForProductId,
    getItemInCart,
  };
});
