import { v4 as uuidv4 } from 'uuid';

export const useLoop54UserIdCookie = () => {
  const { defaultCookieDomain } = useRuntimeConfig().public;

  const cookie = useCookie('loop54-user-id', {
    path: '/',
    domain: defaultCookieDomain,
    maxAge: 365 * 24 * 60 * 60, // 1 year
  });

  if (!cookie.value) {
    cookie.value = uuidv4();
  }

  return cookie;
};
